















































































import _ from 'lodash';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  name: 'Evaluation',
  props: {
    exhibitionId: {
      type: Number,
    },
  },
  data(): any {
    return {
      dialogVisible: false,
      colors: ['#E50113', '#E50113', '#E50113'],
      score: {
        audienceScore: 0, // 观众专业水平
        effctScore: 0, // 影响力
        exhibitionScore: 0, // 展会专业水平
        exhibitorScore: 0, // 展商专业水平
        hostScore: 0, // 主办方背景
      },
    };
  },
  methods: {
    // 关闭
    handleClose() {
      this.dialogVisible = false;
    },
    async handleSubmit() {
      const res: any = await this.$axios.post('/api/exhibition/score', {
        ...this.score,
        exhibitionId: this.exhibitionId,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.handleClose();
      }
    },
  },
  watch: {
    async dialogVisible(data: boolean) {
      if (data) {
        const res: any = await this.$axios.get(
          `/api/exhibition/queryUserScore/${this.exhibitionId}`
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.score = {
            audienceScore: _.get(res, 'data.audience', 0), // 观众专业水平
            effctScore: _.get(res, 'data.effct', 0), // 影响力
            exhibitionScore: _.get(res, 'data.exhibition', 0), // 展会专业水平
            exhibitorScore: _.get(res, 'data.exhibitor', 0), // 展商专业水平
            // hostScore: _.get(res, 'data.host', 0), // 主办方背景
            hostScore: _.get(res, 'data.dcdnHost', 'data.host'), // 主办方背景
            id: _.get(res, 'data.id', null),
          };
        }
      }
    },
  },
});
