



































































import Vue from 'vue';

export default Vue.extend({
  name: 'Goods',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isHot: {
      type: Boolean,
      default: false,
    },
    smallTag: {
      type: Boolean,
      default: false,
    },
  },
});
