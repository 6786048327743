












































import Vue from 'vue';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  name: 'NewsItem2',
  props: {
    news: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleNewsUrl,
  },
});
