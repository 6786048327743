















































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'dialogSubsidy',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    exhibitionId: {
      type: Number,
    },
  },
  data() {
    return {
      subsidyList: [],
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getSubsidyList();
      }
    },
  },
  methods: {
    handleLogin() {
      this.$login.show();
    },
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
    getSubsidyList() {
      this.$axios
        .post(`/api/subsidy/listByEid`, {
          exhibitionId: this.exhibitionId,
        })
        .then((res: any) => {
          if (res.code == 1) {
            this.subsidyList = res.data;
          }
        });
    },
    handleView(row: any) {
      window.open(row.url);
    },
    handleDownload(row: any) {
      // const link = document.createElement('a');
      // // link.download = `${this.info.exhibitionName}.png` || 'image.png';
      // link.href = row.url; // "https://jufair.oss-cn-shanghai.aliyuncs.com/template/exhibitor.xlsx"
      // link.click();

      const link = document.createElement('a');
      const fname = row.fileName; // 下载文件的名字+后缀名
      link.href = row.url;
      link.target = '_blank';
      link.setAttribute('download', fname);
      document.body.appendChild(link);
      link.click();
    },
  },
});
