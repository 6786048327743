import { render, staticRenderFns } from "./content.vue?vue&type=template&id=7a8d6fe2&scoped=true&"
import script from "./content.vue?vue&type=script&lang=ts&"
export * from "./content.vue?vue&type=script&lang=ts&"
import style0 from "./content.vue?vue&type=style&index=0&id=7a8d6fe2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a8d6fe2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default,HotelItem: require('/root/workspace/com.jufair.vue_Z6yh/components/Hotel/HotelItem.vue').default,AirTicketSearch: require('/root/workspace/com.jufair.vue_Z6yh/components/AirTicket/Search.vue').default,FairUser: require('/root/workspace/com.jufair.vue_Z6yh/components/FairUser/index.vue').default,MediaBox: require('/root/workspace/com.jufair.vue_Z6yh/components/MediaBox/index.vue').default})
