























































































































































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'Booth',
  props: {
    booth: {
      type: Object,
    },
    exhibitionId: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  methods: {
    handleLogin() {
      this.$login.show();
    },
  },
});
