















































































































































































































import Vue from 'vue';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import { message } from '~/util/common';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  name: 'Route',
  props: {
    detail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: undefined,
        linkmanPhoneNumber: '',
        content: '',
        peopleNum: undefined,
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '展品内容不能为空', trigger: 'blur' },
        ],
        peopleNum: [
          { required: true, message: '人数不能为空', trigger: 'blur' },
        ],
      },
      cooperateTypeList: [
        {
          value: 13,
          label: '展团行程服务',
          check: false,
          url: require('@/assets/images/apply_zhanwei.jpg'),
        },
        {
          value: 11,
          label: '酒店预订服务',
          check: false,
          url: require('@/assets/images/apply_jiudian.jpg'),
        },
        {
          value: 1,
          label: '签证办理服务',
          check: false,
          url: require('@/assets/images/apply_qianzheng.jpg'),
        },
        {
          value: 10,
          label: '机票预订服务',
          check: false,
          url: require('@/assets/images/apply_jipiao.jpg'),
        },
      ],
    };
  },
  computed: {
    days(): number {
      const { startTime, endTime } = this.detail;
      return moment(endTime).diff(moment(startTime), 'days') + 4;
    },
  },
  methods: {
    moment,
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios } = this;
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
    getType(data: any) {
      data.check = !data.check;
    },
    submitForm(formName: string) {
      const $form = this.$refs[formName] as ElForm;
      $form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.handleApplyBooth();
        } else {
          return false;
        }
      });
    },
    async handleApplyBooth() {
      let serviceTypeList: any = [];
      this.cooperateTypeList.forEach((item: any) => {
        if (item.check) {
          serviceTypeList.push(item.value);
        }
      });
      if (serviceTypeList && serviceTypeList.length === 0) {
        this.isLoading = false;
        message.call(this, {
          delay: 3000,
          title: '请您勾选需要咨询的服务',
          type: 'error',
        });
        return;
      }
      const { id } = this.$route.params;
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: +id,
        serviceTypeList: serviceTypeList,
      });
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        this.cooperateTypeList.forEach((item: any) => {
          item.check = false;
        });
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '服务咨询成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
        // this.$router.push(`/exhibition/${id}.html`);
      }
    },
  },
});
