






























































































































import Vue from 'vue';
export default Vue.extend({
  name: 'Booth',
  props: {
    booth: {
      type: Object,
      default: () => ({}),
    },
    exhibitionId: {
      type: Number,
      default: null,
    },
  },
});
