

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import moment from 'moment';
import Vue from 'vue';
import { mapState } from 'vuex';
import xss from 'xss';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';
import { blackList } from '@/util/blackFair';
import { TraceCode } from '~/util/trace';
import {
  bucketCdnHost,
  bucketHost,
  getContent,
  MINI_HOST,
  sleep,
  message,
  baiduKey2,
  getXOssProcess,
  decryptAndReplace,
} from '~/util/common';

export default Vue.extend({
  name: 'NewsExh',
  filters: {
    dateFormat(date: string) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm');
    },
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default: () => {},
    },
    follow: {
      type: Object,
      default: {
        data: [],
        total: '',
      },
    },
  },
  watch: {
    list(e) {
      
      e.map((item: any) => {
        item['picList'] = item.resourceUrl.split(',');
        if (item.resourceType == 1) this.boothImageList.push(item);
        if (item.resourceType == 2) this.sceneImageList.push(item);
        if (item.resourceType == 3) this.exhibitionHallList.push(item);
        if (item.resourceType == 4) this.videoList.push(item);

        
      });
    },
  },
  data(): any {
    return {
      blackList,
      activeName: 'first',
      showTitle: true,
      dialogShow: false,
      dialogTitle: '',
      dialogImg: '',
      messageList: [],
      newPeriodical: {},
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.detail.photos) {
          this.detail.photos.slice(1).map((item: { resourceUrl: any }) => {
            newArr.push(item.resourceUrl);
          });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.detail.pic;
      }
    },
    hasPhoneOrEmail(): string {
      const val: string[] = [];
      const { containPhoneEmail = [] } = this.newPeriodical;
      if (containPhoneEmail.includes('0')) {
        val.push('电话');
      }
      if (containPhoneEmail.includes('1')) {
        val.push('邮箱');
      }
      if (containPhoneEmail.includes('2')) {
        val.push('官网');
      }
      return val.length > 0 ? `含${val.join('、')}` : '';
    },
  },
  methods: {
    getContent,
    handleTab(i: number) {
      this.activeIndex = i;
    },
    handleShowMini() {
      this.dialogShow = true;
      this.dialogTitle = '扫码打开小程序';
      this.dialogImg = require('~/assets/images/juzhan-mini.jpg');
    },
    getBusiness(tag: any) {
      if (tag === 'visa') {
        this.$router.push(`/visa-0-0-0-0-1/`);
      } else {
        window.open(`${this.$config.HOTEL}`, '_self');
      }
    },
    handleView() {
      
      return false;
      this.$router.replace({});
    },
    async getOrderMessage() {
      const id = _.get(this, '$route.params.id', null);
      if (!id) return;
      const res = await this.$axios.post(
        `/api/todayMessagesApi/getMessages/${id}`
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.messageList = res.data || [];
      }
    },
    toExposer() {
      // this.$refs.exposer?.scrollIntoView(true);
      this.$windowOpen(`/community/${this.$route.params.id}.html`, '_blank');
    },
    // 门票预订
    async handleToTicket() {
      if (this.detail.thirdUrl) {
        const token = this.$cookies.get('access_token');
        if (!token) {
          await this.$store.commit(
            'user/setOrigins',
            location.origin + this.$route.fullPath
          );
          await this.$store.commit('user/setAction', 2);
          await this.$store.commit('user/setIndustryId', this.detail.industry1);

          this.$login.show();
        } else {
          window.trace?.upload({
            module: TraceCode.MO_FAIR_TICKET,
            bizId: this.$route.params.id,
            callbackCode: TraceCode.CC_TICKET_OUT,
          });
          window.open(this.detail.thirdUrl, '_blank');
        }
      } else {
        window.trace?.upload({
          module: TraceCode.MO_FAIR_TICKET,
          bizId: this.$route.params.id,
          callbackCode: TraceCode.CC_TICKET_IN,
        });
        this.$windowOpen(`/ticket/${this.detail.exhibitionId}.html`, '_blank');
        // window.open(
        //   `/ticket/${this.detail.exhibitionId}.html`,
        //   '_blank'
        // );
      }
    },
    // 申请展位
    handleApply(_tag: number) {
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 1);
      //   await this.$store.commit('user/setIndustryId', this.detail.industry1);
      //
      //   this.toLogin({ to: `/exhibition/apply/${this.$route.params.id}.html` });
      //   return;
      // }
      // this.$router.push({
      //     name:'Child',
      //     params:{
      //       id:123
      //     }
      // })
      // 1.展位图
      this.$windowOpen(
        `/exhibition/apply/${this.$route.params.id}.html`,
        '_blank'
      );
      // this.$router.push(
      //   `/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
      // );
      // 埋点
      window.trace?.upload({
        module: TraceCode.MO_FAIR_BOOTH,
        bizId: this.$route.params.id,
        callbackCode: TraceCode.CC_APPLY_BOOTH,
      });
      // window.open(`/exhibition/apply/${this.$route.params.id}.html`, '_blank');
    },
    // 阅读会刊
    readPeriodical() {
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 3);
      //   await this.$store.commit('user/setIndustryId', this.detail.industry1);
      //
      //   this.toLogin({
      //     to: `/periodical/${this.$route.params.id}.html`,
      //   });
      //   return;
      // }
      this.$windowOpen(`/periodical/${this.$route.params.id}.html`, '_blank');
      // 埋点
      window.trace?.upload({
        module: TraceCode.MO_FAIR_PERIODICAL,
        bizId: this.$route.params.id,
        callbackCode: TraceCode.CC_APPLY_PERIODICAL,
      });
      // this.$router.push(`/periodical/${this.$route.params.id}.html`);
    },
    async fetchNewPeriodical() {
      const id = _.get(this, '$route.params.id', null);
      const res = await this.$axios.get(
        `/api/periodicalApi/getNewPeriodical/${id}`
      );

      if (res.code === ResponseCode.SUCCESS && res.data) {
        this.newPeriodical = {
          ...res.data,
          containPhoneEmail: res.data.containPhoneEmail?.split(',') || [],
        };
      }
    },
  },
});
