




































































import Vue from 'vue';
import { getXOssProcess } from '~/util/common';

export default Vue.extend({
  name: 'FairItem',
  methods: {
    getXOssProcess,
    handleDetail() {
      let text = this.$router.resolve({
        path: `/exhibition/${this.item.exhibitionId}.html`,
      });
      window.open(text.href, '_blank');
    },
  },
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
});
